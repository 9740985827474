var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "记录详情",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container list"
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _vm._l(_vm.list, function (transGoods, index) {
    return _c('div', {
      key: index,
      staticClass: "goods"
    }, [_c('div', [_c('van-image', {
      staticClass: "goods-image",
      attrs: {
        "src": transGoods.image
      }
    })], 1), _c('div', {
      staticClass: "goods-info"
    }, [_c('div', {
      staticClass: "goods-name"
    }, [_vm._v(_vm._s(transGoods.name))]), _c('div', {
      staticClass: "goods-size"
    }, [_vm._v("规格：1 * " + _vm._s(transGoods.size))]), _c('div', {
      staticClass: "goods-quantity"
    }, [_c('span', [_vm._v("数量: ")]), transGoods.large_volume ? _c('span', [_vm._v(" " + _vm._s(transGoods.large_volume) + _vm._s(transGoods.large_unit) + " ")]) : _vm._e(), transGoods.least_volume ? _c('span', [_vm._v(" " + _vm._s(transGoods.least_volume) + _vm._s(transGoods.least_unit) + " ")]) : _vm._e()])])]);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }